<script setup lang="ts">
import { onMounted, computed } from 'vue'
import dayjs from 'dayjs'
import { useI18n } from 'vue-i18n'

import { useSubscriptionStore } from '@/store/subscription/subscriptionStore'
import createHttpClient from '@/api/httpClient'
import { SubscriptionData } from '@/shared/subscription'

const { t } = useI18n()

const subscriptionStore = useSubscriptionStore()
const httpClient = createHttpClient()

const choice = computed(() => {
  let currentSubscription = ''

  if (subscriptionStore.subscriptionStatus['type'] === 'annually') {
    currentSubscription = 'annual'
  } else if (subscriptionStore.subscriptionStatus['type'] === 'life') {
    currentSubscription = 'lifetime'
  } else {
    currentSubscription = subscriptionStore.subscriptionStatus['type']
  }

  return currentSubscription
})

onMounted(() => {
  subscriptionStore.asignPlanChoise(
    SubscriptionData[choice.value][0],
    subscriptionStore.subscriptionStatus['tva'],
  )
})

const nextBillDate = computed(() => {
  if (!subscriptionStore.subscriptionStatus['nextBillDate']) return false
  return subscriptionStore.subscriptionStatus['nextBillDate']
})

const remainingDays = computed(() => {
  if (subscriptionStore.subscriptionStatus['trial']) {
    const today = dayjs()
    const target = dayjs(subscriptionStore.subscriptionStatus['trial'])
    const day = target.diff(today, 'day')
    if (day > 0) {
      return day
    }
    return 0
  }
})

const monthsRemaining = computed(() => {
  const currentDate = dayjs()

  if (!subscriptionStore.subscriptionStatus['discountEndDate']) return

  const endDate = dayjs(subscriptionStore.subscriptionStatus['discountEndDate'])

  const months_remaining = endDate.diff(currentDate, 'month', true)

  return Math.ceil(months_remaining)
})

const discountAmount = computed(() => {
  return subscriptionStore.subscriptionStatus['discount']
})

const discountEndDate = computed(() => {
  return subscriptionStore.subscriptionStatus['discountEndDate']
})

const nextAmountBill = computed(() => {
  return subscriptionStore.subscriptionStatus['actualPrice']
})

const tva = computed(() => {
  return subscriptionStore.subscriptionStatus['tva']
})

const monthsAlreadyPaid = computed(() => {
  return subscriptionStore.subscriptionStatus['monthsAlreadyPaid']
})
</script>

<template>
  <div class="subscription-info w-100">
    <div class="title d-flex align-center justify-space-between pa-2">
      <span class="title-1 font-weight-medium"
        >{{ t('have_subscribe_to') }} :
      </span>
      <span class="title-2 font-weight-medium">{{ t('holitime_ref') }}</span>
    </div>

    <!-- abonnement a vie  -->

    <div
      v-if="subscriptionStore.subscriptionStatus['type'] === 'life'"
      class="d-flex flex-column pt-2 ga-5"
    >
      <div
        style="font-size: 14px"
        class="font-italic text-gray-80 d-flex justify-space-between"
      >
        <span v-html="t('life_msg')"></span>
        <span class="text-gray-800 font-weight-bold text-right">-</span>
      </div>

      <div
        v-if="nextBillDate"
        style="font-size: 14px"
        class="font-italic text-gray-80 d-flex justify-space-between"
      >
        <span>{{ t('next_bill_date') }}:</span>
        <span class="text-gray-800 font-weight-bold">{{
          dayjs(nextBillDate).format('D MMMM YYYY')
        }}</span>
      </div>

      <div
        style="font-size: 14px"
        class="font-italic text-gray-80 d-flex justify-space-between"
      >
        <span>{{ t('next_payment_amount') }}:</span>
        <span class="text-gray-800 font-weight-bold"
          >{{ nextAmountBill }} € {{ tva > 0 ? 'TTC' : 'H.T' }}</span
        >
      </div>
    </div>

    <!-- pre-subscription user  -->
    <div
      v-else-if="
        subscriptionStore.subscriptionStatus['type'] === 'monthly' &&
        subscriptionStore.subscriptionStatus['monthsAlreadyPaid'] > 0
      "
      class="d-flex flex-column pt-2 ga-5"
    >
      <div
        style="font-size: 14px"
        class="font-italic text-gray-80 d-flex justify-space-between"
      >
        <span
          v-html="t('presubscribe_msg', { value: monthsAlreadyPaid })"
        ></span>
        <span class="text-gray-800 font-weight-bold">{{
          t('months_remaining', {
            value:
              subscriptionStore.subscriptionStatus['discountMonthsRemaining'] ??
              0,
          })
        }}</span>
      </div>

      <div
        v-if="nextBillDate"
        style="font-size: 14px"
        class="font-italic text-gray-80 d-flex justify-space-between"
      >
        <span>{{ t('next_bill_date') }}:</span>
        <span class="text-gray-800 font-weight-bold">{{
          dayjs(nextBillDate).format('D MMMM YYYY')
        }}</span>
      </div>

      <div
        style="font-size: 14px"
        class="font-italic text-gray-80 d-flex justify-space-between"
      >
        <span>{{ t('next_payment_amount') }}:</span>
        <span class="text-gray-800 font-weight-bold"
          >{{ nextAmountBill }} € {{ tva > 0 ? 'TTC' : 'H.T' }}</span
        >
      </div>
    </div>

    <div v-else class="d-flex flex-column pt-2 ga-5">
      <div
        style="font-size: 14px"
        class="font-italic text-gray-80 d-flex justify-space-between"
      >
        <span v-html="t('discount_benefit', { value: discountAmount })"></span>
        <span class="text-gray-800 font-weight-bold text-right">{{
          t('months_remaining', { value: monthsRemaining })
        }}</span>
      </div>

      <div
        style="font-size: 14px"
        class="font-italic text-gray-80 d-flex justify-space-between"
      >
        <span>{{ t('trial_period') }}:</span>
        <span
          v-if="remainingDays > 0"
          class="text-gray-800 text-right font-weight-bold"
          >{{ t('remaining_date', { value: remainingDays }) }}</span
        >
        <span
          class="text-gray-800 font-weight-bold text-right"
          v-else
          v-html="
            t('remaining_date_pass', {
              value: dayjs(
                subscriptionStore.subscriptionStatus['trial'],
              ).format('D MMMM YYYY'),
            })
          "
        ></span>
      </div>

      <div
        v-if="nextBillDate"
        style="font-size: 14px"
        class="font-italic text-gray-80 d-flex justify-space-between"
      >
        <span>{{ t('next_bill_date') }}:</span>
        <span class="text-gray-800 font-weight-bold text-right">{{
          dayjs(nextBillDate).format('D MMMM YYYY')
        }}</span>
      </div>

      <div
        style="font-size: 14px"
        class="font-italic text-gray-80 d-flex justify-space-between"
      >
        <span>{{ t('next_payment_amount') }}:</span>
        <span class="text-gray-800 font-weight-bold text-right"
          >{{ nextAmountBill }} € {{ tva > 0 ? 'TTC' : 'H.T' }}</span
        >
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.subscription-info .title {
  background-color: #ededed;
  font-size: 16px;
}

.title-2 {
  color: #029978;
}

.title-1 {
  color: #616161;
}

@media (max-width: 599px) {
  .subscription-info .title {
    font-size: 13px;
  }
}
</style>
