<template>
  <v-container fluid>
    <v-row class="d-flex align-center justify-space-between">
      <v-col cols="12" md="8" class="py-0">
        <div class="page-header my-10">
          <h2 class="title">
            {{ $t('dashboard.subscription.my_subscription') }}
          </h2>
          <Breadcrumb
            :currentPage="$t('dashboard.subscription.my_subscription')"
          />
        </div>
      </v-col>

      <v-col cols="12" sm="4" class="pa-sm-0">
        <span
          class="border border-secondary border-opacity-100 text-center rounded-pill px-5 py-2 d-flex align-center justify-center ga-2"
        >
          <CheckIcon size="20" />
          <span class="activation-date">{{
            $t('dashboard.subscription.activation_date', {
              value: activationDate,
            })
          }}</span>
        </span>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="8" class="py-0">
        <template v-if="subscriptionStore.loadSubscription">
          <div class="text-center py-5 font-italic">chargement...</div>
        </template>
        <template v-else>
          <SubscriptionInfo />
          <div v-if="mobile" class="my-5 d-flex flex-column ga-5">
            <ChangeSubscription color="#F5F5F5" />
            <v-btn
              @click="subscriptionStore.toggleSubscription"
              block
              color="secondary"
              >{{ $t('change_subscription') }}</v-btn
            >
          </div>
        </template>

        <template v-if="subscriptionStore.loadInformation">
          <div class="text-center py-5 font-italic">chargement...</div>
        </template>
        <template v-else>
          <BillingInfo />
        </template>

        <template v-if="subscriptionStore.loadCard">
          <div class="text-center py-5 font-italic">chargement...</div>
        </template>
        <template v-else>
          <PaymentMethode />
        </template>
      </v-col>

      <v-col
        cols="12"
        sm="4"
        class="pa-0 text-center d-flex justify-center w-100"
        v-if="!mobile"
      >
        <template v-if="subscriptionStore.subscriptionStatus['type']">
          <SubscriptionPlan
            class="d-flex flex-grow-1"
            :selectedPlan="selectedPlan"
            :plans="SubscriptionData[selectedPlan]"
          />
        </template>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <template v-if="subscriptionStore.paymentListLoading">
          <div class="text-center py-5 font-italic">chargement...</div>
        </template>
        <template v-else>
          <Payments />
        </template>
      </v-col>
    </v-row>

    <ChangeSubscriptionDialog
      v-if="subscriptionStore.changeSubscription"
      @update:close="subscriptionStore.toggleSubscription"
    />
  </v-container>
</template>

<script lang="ts" setup>
import SubscriptionForm from '@/components/forms/subscription/SubscriptionForm.vue'
import { onMounted, ref, computed, Ref } from 'vue'
import { useAppStore } from '@/store/app'
import createHttpClient from '@/api/httpClient'
import { useI18n } from 'vue-i18n'
import FormBlock from '@/components/common/FormBlock.vue'
import RecapPlan from '@/components/RecapPlan.vue'
// @ts-ignore
import SubscriptionRecap from '@/components/SubscriptionRecap.vue'
import BillingInfo from '@/components/subscription/BillingInfo.vue'
import PaymentMethode from '@/components/subscription/PaymentMethode.vue'
import SubscriptionInfo from '@/components/subscription/SubscriptionInfo.vue'
import Payments from '@/components/subscription/Payments.vue'
import CompanyDialog from '@/components/dialog/CompanyDialog.vue'
import BreakDialog from '@/components/dialog/BreakDialog.vue'
import UpdateCardDialog from '@/components/dialog/UpdateCardDialog.vue'
import CancelSubscription from '@/components/dialog/CancelSubscription.vue'
import MessageService from '@/components/feedback/message/messageService'
import { DashboardStatus } from '@/utils/dashboardStatus'
import { useUserStore } from '@/store/user/userStore'
import { useSubscriptionStore } from '@/store/subscription/subscriptionStore'
import Breadcrumb from '@/components/Breadcrumb.vue'
import { useUserTvaStore } from '@/store/tva/userTvaStore'
import { Plan } from '@/shared/subscription'
import SubscriptionPlan from '@/components/SubscriptionPlan.vue'
import { SubscriptionData } from '@/shared/subscription'
import ChangeSubscription from '@/components/ChangeSubscription.vue'
import ChangeSubscriptionDialog from '@/components/dialog/ChangeSubscriptionDialog.vue'
import CheckIcon from '@/components/icons/CheckIcon.vue'
import { useDisplay, useTheme } from 'vuetify'
import dayjs from 'dayjs'

const { mdAndDown, mobile } = useDisplay()

const subscriptionStore = useSubscriptionStore()
const userStore = useUserStore()
const { t: $t, d, n } = useI18n()
const editDialog = ref(false)
const editSubmitting = ref(false)
const subscriptionForm = ref(null)
const editFinished = ref(false)
const subscriptionFormKey = ref(0)
const appStore = useAppStore()
const subscriptionMonthsAlreadyPaid = ref(null)
const subscriptionMonthsAsked = ref(0)
const isLifetime = ref(false)
const companyInfoDialog = ref(false)
const paymentList = ref([])
const paymentListLoading = ref(false)
const paymentItemsPerPage = 10
const paymentTotalItems = ref(0)
const preSubActive = ref(false)

const selectedPlan = computed(() => {
  if (subscriptionStore.subscriptionStatus['type'] == 'annually') {
    return 'annual'
  } else if (subscriptionStore.subscriptionStatus['type'] == 'life') {
    return 'lifetime'
  } else {
    return subscriptionStore.subscriptionStatus['type']
  }
})

const activationDate = computed(() => {
  return dayjs('2025-09-29').format('D MMMM YYYY')
})

const userTva = useUserTvaStore()

const subscriptionStatus = ref({
  isActive: false,
  isCancel: false,
  isBreak: false,
})

const isBreak = ref(false)
const updateCart = ref(false)
const break_loading = ref(false)

const submitEditSubscription = () => {
  subscriptionForm.value?.submit()
}
// const isSubscribed = ref(true)
const loadingInfo = ref(false)
const companyInfo = ref({
  nameCompany: '',
  addressCompany: '',
  siret: '',
  postalCodeCompany: '',
  cityCompany: '',
  countryCompany: '',
  tvaNumberCompany: '',
})
const cardInfo = ref({
  expiryMonth: '',
  expiryYear: '',
  lastFour: '',
})

const toggleBreakDialog = ref(false)
const cancelPlan = ref(false)

const fetchInformation = async () => {
  const httpClient = createHttpClient()
  appStore.setInnerLoading(true)
  httpClient
    .get('/subscription/current-state')
    .then(response => {
      subscriptionMonthsAsked.value = response.data.totalMonthsAsked
      subscriptionMonthsAlreadyPaid.value = response.data.monthsAlreadyPaid
      isLifetime.value = !!response.data.isLifetime
      subscriptionStatus.value = { ...response.data.subscriptionStatus } // status des différents subscription d'un abonnement
    })
    .finally(() => {
      appStore.setInnerLoading(false)
    })
}

const loadingCancel = ref(false)
//  resiliation d'abonnement

const fetchCompanyInfo = async () => {
  const req = createHttpClient()
  const { data } = await req.get('/account/informations')
  companyInfo.value = {
    ...companyInfo.value,
    nameCompany: data.nameCompany,
    addressCompany: data.addressCompany,
    siret: data.siret,
    countryCompany: data.countryCompany,
    cityCompany: data.cityCompany,
    postalCodeCompany: data.postalCodeCompany,
    tvaNumberCompany: data.tvaNumberCompany,
  }
}

const fetchPaymentMethod = async () => {
  const resp = await subscriptionStore.fetchCardInfo()
}

const subscriptionAvailable = async () => {
  const request = createHttpClient()
  const resp = await request.get('/subscription/available-subscription')
}

const subscriptionload = ref(true)

const displayAmount = computed(() => {
  if (
    subscriptionStore.subscriptionStatus['monthsAlreadyPaid'] > 0 &&
    subscriptionStore.subscriptionStatus['monthsAlreadyPaid'] > 1 &&
    subscriptionStore.subscriptionStatus['status'] == 'active'
  ) {
    return true
  }
})

const countryTva = computed(() => {
  return subscriptionStore.subscriptionStatus['tva'] ?? userTva.countryTva?.tva
})

// status de l'abonement en cour du praticien
const fetchCurrentSubscriptionStatus = async () => {
  try {
    subscriptionload.value = true
    // fetch subscription status
    await subscriptionStore.fetchSubscriptionStatus()
  } catch (err) {
  } finally {
    subscriptionload.value = false
  }
}

const canActive = ref()

const dashBoardState = async () => {
  const req = createHttpClient()

  const resp = await req.get('/account/dashboard-status')

  canActive.value = DashboardStatus(resp.data)
}

const plans = ref<Plan[]>()

onMounted(async () => {
  userTva.getUserTva()

  fetchInformation()
  fetchCompanyInfo()
  fetchPaymentMethod()
  subscriptionAvailable()
  fetchCurrentSubscriptionStatus()

  dashBoardState()
})
</script>

<style lang="scss">
.page-header .title {
  font-weight: 700px;
  font-size: 33px;
  line-height: 46.2px;
  color: #004838;
  font-weight: bold;
}

.activation-date {
  font-size: 14px;
  color: #9e9e9e;
}

@media (max-width: 599px) {
  .activation-date {
    font-size: 12px;
  }
}
</style>
